import React from 'react';
import { Button, Typography } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';

const { Title } = Typography;

const LogoutPage = () => {
    const handleLogout = () => {
        // Perform logout logic here
        localStorage.removeItem('userData');
        console.log('Logging out...');
        alert("Logout Completed ... ")
        window.location.href = '/studentLogin';
        // Redirect to the login page or perform any other necessary actions
    };

    return (
        <div style={styles.container}>
            <Title level={2} style={styles.title}>
                Logout Successful 👍
            </Title>
            <Button
                type="primary"
                shape="circle"
                size="large"
                icon={<PoweroffOutlined style={styles.powerOffIcon} />}
                onClick={handleLogout}
            />
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#001529', // Ant Design dark background color
        color: 'white',
    },
    title: {
        marginBottom: '20px',
        color: '#1890ff', // Ant Design primary color
    },
    powerOffIcon: {
        fontsize: '1.4em',
        color: 'black',
    },
};

export default LogoutPage;
