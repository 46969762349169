import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/learning-power-logo.png';

import {
  UsergroupAddOutlined,
  FieldTimeOutlined,
  CheckCircleOutlined,
  IdcardOutlined,
  UserAddOutlined,
  FormOutlined,
  BarChartOutlined,
  RiseOutlined,
  LineChartOutlined,
  PieChartOutlined,
  BellOutlined,
  ShoppingOutlined,
  BookOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);

  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);

  const onCollapse = () => {
    navMenu.collapse();
  };

  return (
    <>
      <Sider collapsible collapsed={isNavMenuClose} onCollapse={onCollapse} className="navigation">
        <div className="logo">
          <img
            src={logoIcon}
            alt="Logo"
          />
        </div>
        <Menu mode="inline" style={{ backgroundColor: '#fff' }}>
          {/* <Menu.Item key={'Dashboard'} icon={<DashboardOutlined />}>
            <Link to={'/'} />
            Dashboard
          </Menu.Item> */}
          <Menu.Item key={'Customer'} icon={<UsergroupAddOutlined />}>
            <Link to={'/student'} />
            Students
          </Menu.Item>
          <Menu.Item key={'dashboard'} icon={<DashboardOutlined />}>
            <Link to={'/dashboard'} />
            Dashboard
          </Menu.Item>
          <Menu.Item key={'Approved'} icon={<CheckCircleOutlined />}>
            <Link to={'/approved'} />
            Approved
          </Menu.Item>
          <Menu.Item key={'Pending'} icon={<FieldTimeOutlined />}>
            <Link to={'/pending'} />
            Pending
          </Menu.Item>
          <Menu.Item key={'Basics'} icon={<PieChartOutlined />}>
            <Link to={'/basic'} />
            Basic Course
          </Menu.Item>
          <Menu.Item key={'amc1'} icon={<LineChartOutlined />}>
            <Link to={'/amc1'} />
            Advanced 1
          </Menu.Item>
          <Menu.Item key={'amc2'} icon={<RiseOutlined />} >
            <Link to={'/amc2'} />
            Advanced 2
          </Menu.Item>
          <Menu.Item key={'Invoice'} icon={<IdcardOutlined />}>
            <Link to={'/invoice'} />
            Indicator Access
          </Menu.Item>
          <Menu.Item key={'Quote'} icon={<FormOutlined />}>
            <Link to={'/createForm'} />
            Create Forms
          </Menu.Item>
          <Menu.Item key={'PaymentInvoice'} icon={<BarChartOutlined />}>
            <Link to={'/form'} />
            Form Submission Data
          </Menu.Item>
          <Menu.Item key={'Notification'} icon={<BellOutlined />}>
            <Link to={'/notification'} />
            Notifications
          </Menu.Item>
          <Menu.Item key={'Offers'} icon={<ShoppingOutlined />}>
            <Link to={'/offers'} />
            Offers
          </Menu.Item>
          <Menu.Item key={'studyMaterial'} icon={<BookOutlined />}>
            <Link to={'/studyMaterial'} />
            Study Material
          </Menu.Item>
          <Menu.Item key={'batch'} icon={<DatabaseOutlined />}>
            <Link to={'/batch'} />
            Create Batch
          </Menu.Item>
          <Menu.Item key={'email'} icon={<FolderOpenOutlined />}>
            <Link to={'/email'} />
            Email Marketing
          </Menu.Item>
          <Menu.Item key={'ib'} icon={<UserSwitchOutlined />}>
            <Link to={'/ib'} />
            IB
          </Menu.Item>
          {/* <Menu.Item key={'Admin'} icon={<UserAddOutlined />}>
            <Link to={'/admin'} />
            Admin
          </Menu.Item> */}
          {/* <SubMenu key={'Settings'} icon={<SettingOutlined />} title={'Settings'}>
            <Menu.Item key={'SettingsPage'}>
              <Link to={'/settings'} />
              General Settings
            </Menu.Item>
            <Menu.Item key={'PaymentMode'}>
              <Link to={'/payment/mode'} />
              Payment Mode
            </Menu.Item>
            <Menu.Item key={'Role'}>
              <Link to={'/role'} />
              Role
            </Menu.Item>
          </Sub Menu> */}
        </Menu>
      </Sider>
    </>
  );
}
