import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import LogoutPage from '@/forms/LogoutPage';

const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/Login'));
const studentPanel = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/studentPanel'));
const thanks = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/thanks'));

const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const RegistrationForm = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/forms/RegistrationForm'));
const StudentLoginForm = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/forms/StudentLoginForm'));
const DynamicForm = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/Survey'));
const basicRegistration = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/forms/basicRegistration.jsx'));
const master1 = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/forms/masterRegistration'));
const master2 = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/forms/masterRegistrationLevel2'));

export default function AuthRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PublicRoute path="/" component={Login} render={() => <Redirect to="/login" />} exact />
          <PublicRoute component={Login} path="/login" exact />
          <PublicRoute component={RegistrationForm} path="/register" exact />
          <PublicRoute component={StudentLoginForm} path="/studentLogin" exact />
          <PublicRoute component={studentPanel} path="/studentPanel" exact />
          <PublicRoute component={thanks} path="/thanks" exact />
          <PublicRoute component={DynamicForm} path="/forms" exact />
          <PublicRoute component={basicRegistration} path="/basic" exact />
          <PublicRoute component={master1} path="/master1" exact />
          <PublicRoute component={master2} path="/master2" exact />
          <PublicRoute component={LogoutPage} path="/exit" exact />
          <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
